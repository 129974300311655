import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const Repairer = () => {
    const fontSize = '18px';

    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Мастер на час</h3>
                    </Col>
                    <Col xs={12}> <p style={{fontSize}}>Относительно новая услуга "мастер на час" или как её часто называют в обиходе "муж на час", пользуется большим спросом среди очень занятых людей, домохозяек и пенсионеров. Довольно часто в доме или в офисе возникают небольшие проблемы, а у Вас нет времени, сил, необходимых навыков и инструментов. А может просто нет желания разбираться с возникшей ситуацией и решать мелкие домашние проблемы. В таком случае, наши "мастера на час" внимательно Вас выслушают, дадут необходимую консультацию и в кратчайшие сроки, исключительно в удобное для Вас время, приедут к Вам домой или в офис, чтобы вежливо, быстро и качественно устранить возникшую проблему.</p></Col>
                    <Col md={6}>
                       <p style={{fontSize}}>Наши мастера вежливые и профессиональные специалисты своего дела, которые справятся с любой поломкой быстро и самостоятельно. Они всегда приезжают с собственным инструментом, запчастями и материалам, необходимыми для решения Вашей задачи. Опыт и навыки наших "мастеров на час" помогут справиться с любой бытовой проблемой. Наши мастера могут повесить карниз, полку, люстру или зеркало, подключить светильник, устранить течь из крана, отрегулировать мебель, окна и двери, заменить разбившееся стекло, плитку или часть ламината, исправить шалости малышей или Ваших домашних любимцев. А иногда и последствия бурной и весёлой вечеринки.</p>
                       <p style={{fontSize}}>Услуга "мастер на час" набирает всё большую популярность, ведь всё меньше людей желают самостоятельно разбираться с мелкими домашними неполадками и всё больше доверяют такую работу умелым мастерам.</p>
                       <p style={{fontSize}}>Мы не делаем очень дёшево, мы делаем не дорого, быстро и качественно!</p>
                    </Col>
                    <Col md={6}>
                        <img width="100%" src="/img/handyman.jpg" alt="hourly repairer" />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default Repairer;
