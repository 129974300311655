import PageWrapper from "../../components/page-wrapper";
import SimpleArticle from "../../components/simple-article";
import { Container, Row, Col } from 'react-bootstrap';


const About = () => {
    return (
        <PageWrapper>
            <Container>
                <Row>
                    <Col>
                        <SimpleArticle
                            title="О нас"
                            text1="Команда Стар-Макс -это профессиональный, дружный коллектив специалистов, работающий в разных странах Европы. Каждый из нас, квалифицированный специалист в своей сфере деятельности. А это недвижимость, финансы, право, IT, медицина, искусство, туризм, образование, машиностроение и авиация. И это наша особенность. Мы говорим на многих языках. Наша команда всё время развивается. Поэтому, для наших Клиентов доступно такое разнообразие предлагаемых нами услуг. Мы постоянно расширяем нашу географию и перечень наших возможностей. Список наших Партнёров неуклонно увеличивается."
                            text2="Мы постоянно стараемся учитывать тенденции развития современного мира. А использование новых идей и технологий сокращает время выполнения работ, повышает качество и несомненно улучшает настроение наших Клиентов!"
                            text3="И мы никогда не беремся за работу, которую не можем выполнить качественно. Но мы всегда предложим Вам услуги наших Партнёров, которые сделают всё так, как Вам будет угодно."
                        />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default About
