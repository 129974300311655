import styles from './index.module.scss'

const SimpleArticle = ({ title, text1, text2, text3, lists, listTitle, listItems, bigMarginStyle }) => {
    return (
        <article className={bigMarginStyle ? styles.bigMarginStyle : styles.article}>
            {title ? <h2 className='text-center py-5'>{title}</h2> : ''}
            <p>{text1}</p>
            <p>{text2}</p>
            <p>{text3}</p>

            <h3 className="mb-4">{listTitle}</h3>

            <ul className="mb-5">
                {listItems?.map((listItem, i) => (
                    <li key={i}>{listItem}</li> 
                ))}
            </ul>
        </article>
    )
}

export default SimpleArticle;
