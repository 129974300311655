import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

const Souvenirs = () => {
    const images = [
        {
          original: '/img/art1.jpg',
          thumbnail: '/img/art1.jpg',
        },
        {
          original: '/img/art2.jpg',
          thumbnail: '/img/art2.jpg',
        },
        {
          original: '/img/art3.jpg',
          thumbnail: '/img/art3.jpg',
        },
        {
            original: '/img/art4.jpg',
            thumbnail: '/img/art4.jpg'
        },
        {
            original: '/img/art5.jpg',
            thumbnail: '/img/art5.jpg'
        },
        {
            original: '/img/art6.jpg',
            thumbnail: '/img/art6.jpg'
        },
        {
            original: '/img/art7.jpg',
            thumbnail: '/img/art7.jpg'
        },
        {
            original: '/img/art8.jpg',
            thumbnail: '/img/art8.jpg'
        }
      ];

    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Сувениры</h3>
                        <p>Приятно дарить подарки и сувениры, которые не купить в магазине и сувенирной лавке? Ищите что-то индивидуальное другу, коллеге или любимому человеку? А может уникальный дизайнерский элемент интерьера?</p>
                        <p>Наши мастера и их уникальные изделия всегда к Вашим услугам.</p>
                        <p>Вы всегда найдете интересные для Вас изделия или сможете заказать эксклюзивный авторский предмет искусства, такой, как Вы его видите.</p>
                        <h3 className="text-center my-4 text-primary">Наша артгруппа</h3>
                    </Col>
                    <Col xs={12} md={6} lg={5}>
                        <p>Примеры работ:</p>

                       <ImageGallery 
                        items={images}
                        showPlayButton={false}
                        lazyLoad={true} />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default Souvenirs;
