import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';
import '../../App.scss'
import ImageGallery from 'react-image-gallery';

const ForChildren = () => {
    const images = [
        {
          original: '/img/children1.jpg',
          thumbnail: 'img/children1.jpg',
        },
        {
          original: '/img/children2.jpg',
          thumbnail: '/img/children2.jpg',
        },
        {
          original: '/img/children3.jpg',
          thumbnail: '/img/children3.jpg',
        },
        {
            original: '/img/children4.jpg',
            thumbnail: '/img/children4.jpg'
        },
        {
            original: '/img/children5.jpg',
            thumbnail: '/img/children5.jpg'
        },
        {
            original: '/img/children6.jpg',
            thumbnail: '/img/children6.jpg'
        }
      ];

    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Для детей</h3>
                    </Col>
                    <Col xs={12}>
                        <p>Детям всегда всё интересно! Они очень любознательные! А как насчёт итнтересных и весёлых уроков художественного творчества? Творчество в душе каждого ребёнка и наши художники помогут его развить.</p>
                        <p>Ваш ребенок непоседа, любит играть и общаться со сверстниками? Интересный отдых в детском лагере к Вашим услугам. А ещё интересные экскурсии для детей и всей семьи!</p>
                    </Col>
                    <Col xs={12} md={6} lg={5}>
                       <h4>Галерея лагеря</h4>
                       <ImageGallery 
                        items={images}
                        showPlayButton={false}
                        lazyLoad={true} />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default ForChildren;
