import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Hamburger from './hamburger';
import styles from './index.module.scss';
import '../../App.scss';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();

    return (
        <header className={styles.header}>

       
            <Navbar expand="xl" className={styles.navigation + ' bg-light'}>
   
                <Container>
                    <Navbar.Brand as={Link} to="/" > <img width={180} src='/logo.svg' alt="main logo" /> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Brand href="tel:+359 87 985 95 28"> <p className="mb-0"><i className="fa-solid fa-phone-volume"></i> +359 87 985 95 28</p> </Navbar.Brand>
                    <Navbar className={styles.navMenu} id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link active={location.pathname === '/'} as={Link} to="/" className="text-uppercase">Семейные услуги</Nav.Link>
                            <Nav.Link active={location.pathname === '/about'} as={Link} to="/about" className="text-uppercase">О Нас </Nav.Link>

                        </Nav>
                    </Navbar>
                </Container>

                <div className={styles.burger}>
                    <Hamburger />
                </div>
            </Navbar>
            <section className={[styles.services, styles.header].join(' ')}>
                <Container>
                    <Row>
                        <Col lg={8} className='mx-auto'>
                            <article className='py-5 px-3 text-center'>
                                <h1>Предоставляем поддержку недвижимости, авторемонтные, медицинские, развлекательные и информационные услуги</h1>
                            </article>
                        </Col>
                    </Row>
                </Container>
            </section>
        </header>
    )
}

export default Header;
