import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const AutoServices = () => {
    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Автомобильные услуги</h3>
                    </Col>
                    <Col md={6}>
                        <p>Содержание автомобиля сложная задача. Доверьте это нам. Мелкий или сложный ремонт, покраска, сезонное техобслуживание, подготовка к техосмотру, заказ запчастей, безопасное хранение.</p>
                        <p><b>Наши услуги:</b></p>

                        <ul>
                            <li>Аварийный, срочный ремонт на выезде</li>
                            <li>Мелкий ремонт</li>
                            <li>Капитальный ремонт</li>
                            <li>Рихтовка</li>
                            <li>Покраска</li>
                            <li>Сезонное техобслуживание</li>
                            <li>Заказ запасных частей</li>
                            <li>Установка дополнительного оборудования</li>
                            <li>Помощь при продаже и покупке</li>
                            <li>Аренда</li>
                            <li>Хранение</li>
                            <li>Информационная поддержка</li>
                        </ul>

                        <p>Всё это и не только под силу нашим специалистам! Сели в машину, а она не заводится или спустило колесо? Мы всегда приедем к Вам на помощь! Хотите обновить своего стального друга? Мы окажем помощь в продаже или покупке. И всегда подскажем по другим вопросам - обращайтесь!</p>
                    </Col>
                    <Col md={6}>
                        <img width="100%" src="/img/car-repair.jpg" alt="Car repair services" />
                    </Col>
                    <Col xs={12}>
                        <p>Наша команда всегда будет действовать исключительно в Ваших интересах!</p>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default AutoServices;
