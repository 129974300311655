import Footer from "../footer"
import Header from "../header"



const PageWrapper = (props) => {
    return (
        <>
            <Header/>
            <div id="main">{props.children}</div>
            <Footer/>
        </>
    )
}

export default PageWrapper;
