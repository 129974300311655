import PageWrapper from "../../components/page-wrapper";
import SimpleArticle from "../../components/simple-article";
import { Container, Row, Col } from 'react-bootstrap';


const Artgroup = () => {
    return (
        <PageWrapper>
            <Container>
                <Row>
                    <Col className="pt-5">
                        <SimpleArticle
                            bigMarginStyle={true}
                            headline="Артгруппа"
                            text1="Жанна Грин - художник, закончила Санкт-Петербургскую Академию театральных искусств. Специальность-художник постановщик. Участник и номинант международных и российских фестивалей, биеннале, выставок. Персональные работы находятся в частных и общественных коллекциях Норвегии, Франции, Финляндии, Болгарии, Украины, России и др."
                            text2='Александр Тимофеев - технолог, номинирован международным сертификатом "Мастер золотые руки". Простыми средствами, архаичными способами создаёт уникальные авторские изделия.'
                            listTitle='Что мы делаем'
                            listItems={[
                                'Художественно-декоративное оформление помещений, витрин кафе и ресторанов, магазинов т.д.',
                                'Изготовление ростовых, рекламных и корпоративных кукол',
                                'Декоративные работы внутри помещений',
                                'Техническая реставрация: мебель, рамы, текстиль',
                                'Изготовление кукол с портретным сходством',
                                'Художественное оформление спектаклей: сценография, разработка и изготовление костюмов',
                                'Разработка и изготовление авторской коллекционной куклы-фигуративная скульптура',
                                'Авторские сувениры ручной работы, индивидуальная разработка',
                                'Живопись-декоративые панно в смешанной технике'
                            ]}
                        />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default Artgroup;
