import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const Tourism = () => {
    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Туризм и путешествия</h3>
                    </Col>
                    <Col xs={12} className="mb-4">
                        <p>Прилетели в аэропорт и хотите быстро, не дорого, безопасно и с комфортом добраться до места отдыха или к своей недвижимости? Мы с удовольствием встретим Вас, поможем с багажом и организуем всё необходимое для Вас на обратном пути. По дороге, мы предоставим Вам всю необходимую и интересную для Вас информацию. Хотите на экскурсию? Пожалуйста, мы сделаем всё, чтобы Вам было интересно и познавательно! Ну и конечно окажем Вам поддержку 24/7.</p>
                    </Col>
                    <Col md={6}>
                    <p>К Вашим услугам:</p>
                    <ul>
                        <li>Встреча в аэропорту</li>
                        <li>Комфортный трансфер</li>
                        <li>Информация</li>
                        <li>Индивидуальные экскурсии</li>
                        <li>Тематические экскурсии</li>
                        <li>Целевое путешествие по стране</li>
                        <li>Шопинг</li>
                        <li>Организация деловых встреч</li>
                        <li>Организация встреч с юристами, бухгалтерами</li>
                        <li>Посещение риелтерских компаний</li>
                        <li>Посещение оздоровительных центров</li>
                        <li>Особые пожелания</li>
                        <li>Поддержка 24/7</li>
                        <li>Организация отъезда</li>
                    </ul>
                    </Col>
                    <Col md={6}>
                        <img width="100%" src="/img/tourism.jpg" alt="Appliances repair services" />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default Tourism;
