
import Home from './pages/home';
import Error from './ErrorBoundery.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Error>
        <Home/>
      </Error>
    </div>
  );
}

export default App;
