import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const RealEstate = () => {
    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Недвижимость</h3>
                        <p><b>Мы поможем сделать:</b></p>
                        <p><b>Срочный, мелкий и аварийный ремонт.</b> Нужен косметический ремонт после покупки или продажи? А может пришло время для капитального? Хотите купить ли продать? Сдать или взять в аренду? Мы поможем найти и оформить интересный для Вас вариант! Некому присмотреть за жильём, растениями или сделать уборку? Надо обеспечить безопасность? Мы Вам с удовольствием поможем!</p>
                    </Col>
                    <Col md={6}>
                        <p><b>К Вашим услугам:</b></p>

                        <ul>
                            <li>Ремонт балконов и террас</li>
                            <li>Столярные работы</li>
                            <li>Ремонт и установка стеклопакетов</li>
                            <li>Мелкий ремонт помещений</li>
                            <li>Сборка и ремонт мебели</li>
                            <li>Авторский дизайн интерьера</li>
                            <li>Капитальный ремонт</li>
                            <li>Косметический ремонт перед продажей</li>
                            <li>Межсезонный ремонт</li>
                            <li>Просмотр за недвижимостью 365 дней в году</li>
                        </ul>

                        <p><b>К Вашим услугам:</b></p>

                        <ul>
                            <li>Аварийный ремонт сантехники и электрики</li>
                            <li>Забота о растениях</li>
                            <li>Уборка</li>
                            <li>Клининг перед приездом</li>
                            <li>Организация и установка пожарной, охранной сигнализации</li>
                            <li>Видеонаблюдение</li>
                            <li>Аренда</li>
                            <li>Покупка и продажа</li>
                            <li>Услуги риелтора</li>
                            <li>Услуги переводчика</li>
                            <li>Услуги юриста</li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <img width="100%" src="/img/building.jpg" alt="Estate and building services" />
                    </Col>
                    <Col xs={12}>
                        <p><b>Аварийный ремонт сантехники и электрики</b></p>
                        <p>Пропал свет, не работает розетка или выключатель? Течёт кран или забилась раковина? И как всегда неожиданно. Не расстраивайтесь.</p>
                        <p>Сообщите нам и мы быстро и качественно поможем Вам восстановить прежний комфорт в Вашем доме.</p>
                        <p>Наши специалисты оперативно приедут к Вам и устранят возникшие неисправности. И как положено ответственным мастерам, проверят всю сантехнику и электрику на надёжность и работоспособность, чтобы предупредить неожиданные проблемы.</p>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default RealEstate;
