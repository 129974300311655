import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTheContent = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      window.scroll({
        left: 0, 
        top: document.getElementById("main")?.offsetTop ?? 0, 
        behavior: "smooth"
      });
    } else {
      window.scroll(0, 0);
    }
  }, [location])

  return <>{children}</>
}

export default ScrollToTheContent;
