import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './index.module.scss'

const Home = () => {
    return (
        <PageWrapper>
            <main>
                <section className={styles.intro}>
                    <Container>
                        <Row>
                            <Col>
                                <p>
                                    Дома всегда хорошо! Однако, многие из нас, предпочитают отдыхать, жить, учиться, работать или заботиться о своем здоровье за рубежом. И предпочтение, как правило, отдается Европе. Огромное разнообразие возможностей, хороший сервис, комфорт и безопасность привлекают в европейские страны все больше людей со всего мира.
                                    И вот, Вы уже в выбранной вами стране Европы. Все прекрасно, Вы всем довольны! И пусть так будет всегда!
                                    А решение вопросов, возникающих в повседневной жизни, Вы всегда можете поручить нашей команде.
                                    <br />И мы сделаем всё, как Вы пожелаете!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className={styles.categories}>
                    <Container>
                        <Row xs={1} md={2} xl={3} className="g-4">
                            <Col>
                                <Card className={styles.card} as={Link} to="/estate">
                                    <Card.Img variant="top" src="img/categories/real-estate.webp" alt="Real estate service" />
                                    <Button variant="primary">Недвижимость</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/repairer">
                                    <Card.Img variant="top" src="img/categories/maestro.webp" alt="Maesto service" />
                                    <Button variant="primary">Мастер на час</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/appliances">
                                    <Card.Img variant="top" src="img/categories/repair.webp" alt="Repair service" />
                                    <Button variant="primary">Ремонт бытовой техники</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/autoservice">
                                    <Card.Img variant="top" src="img/categories/auto-repair.webp" alt="Auto repair service" />
                                    <Button variant="primary">Автоуслуги</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/medical">
                                    <Card.Img variant="top" src="img/categories/medical.webp" alt="Medical services" />
                                    <Button variant="primary">Медицина</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/souvenirs">
                                    <Card.Img variant="top" src="img/categories/souvenirs.webp" alt="Gifts and souvenirs" />
                                    <Button variant="primary">Авторские подарки и сувениры</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/tourism">
                                    <Card.Img variant="top" src="img/categories/tourist.webp" alt="Tourism services" />
                                    <Button variant="primary">Туризм и путешествия</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/law-services">
                                    <Card.Img variant="top" src="img/categories/law.webp" alt="Law services" />
                                    <Button variant="primary">Юридические услуги</Button>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={styles.card} as={Link} to="/for-children">
                                    <Card.Img variant="top" src="img/categories/child-care.webp" alt="Children services" />
                                    <Button variant="primary">Для детей</Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Container as="section" className={styles.mainInfo}>
                    <Row className="mb-5">
                        <Col xs={12} className={styles.mainTitle}>
                            <h2>Команда, которая решает ваши вопросы в Болгарии</h2>
                        </Col>
                        <Col xs={12}>
                            <p>Иногда, во время пребывания за рубежом, мы сталкиваемся с различными ситуациями, <b>когда просто не знаешь, как поступить, куда и к кому обратиться.</b> Небольшие проблемы, которые к сожалению, рано или поздно возникают у каждого из нас, кажутся в другой стране непреодолимыми. Не заводится автомобиль, течёт кран, не работает кондиционер, болит зуб. А если просто скучно? Как интересно и с удовольствием провести время? Мы знаем, как их решать у себя дома, ведь почти всегда рядом есть кто то, готовый подсказать, поделиться информацией, да и просто помочь. Звонок родным, другу, знакомому специалисту и мы уже знаем, как поступить. Ну и конечно Интернет, в котором всегда доступна информация местных компаний, на знакомом нам языке, с понятными для нас условиями работы.</p>
                        </Col>
                        <Col xs={6}>
                            <p>А в другой стране? Совсем иная картина пребывания и жизни в стране, где всё для нас не совсем понятно. Другой язык, нормы общения, правила поведения, законы и порядки. Оказавшись в такой, мягко говоря, не приятной ситуации, мы как правило испытываем растерянность, чувство одиночества и возникает ощущение безвыходности в сложившихся обстоятельствах. Оглянувшись вокруг, мы лихорадочно ищем человека готового прийти к нам на помощь. Таксист, полицейский, администратор отеля, сотрудник автозаправки. Иногда везёт и тогда кто-нибудь поделится с нами необходимой информацией. Даже Интернет не кажется лёгким выходом.</p>
                            <p>Потратив огромное количество времени и проложив не мало усилий, запрос мы всё-таки сформулируем. А дальше ещё сложнее. Локальные сайты открывают доступ к неизвестному нам функционалу местных фирм, предприятий и организаций. И мы снова оказываемся в полном непонимании, а как же решить свой вопрос?</p>
                        </Col>
                        <Col xs={12} lg={6} className="text-end">
                            <img height="90%" width="95%" src="/img/laptop-job.png" alt="Working on laptop" />
                        </Col>
                        <Col>
                            <p>Это история о нас, о том как мы, в своё время, начинали свой отдых, учёбу, работу и жизнь в другой стране. Да, рано или поздно, у каждого из нас происходит такая вот история.</p>
                            <p>Оглядываясь назад, опираясь на свой опыт, на опыт наших друзей и партнёров, мы решили создать портал услуг, который возможно будет полезен для Вас во время пребывания в другой стране. А может и Вашей родной стране тоже. Поможет Вам чувствовать себя увереннее, комфортнее и не беспокоится в ситуациях, когда так необходима помощь и поддержка.</p>
                            <p>Вся наша команда, всегда внимательно и дружелюбно отнесётся к любой ситуации, которая у Вас возникла. Мы с удовольствием предоставим Вам <b>подробную информацию по Вашему вопросу</b> и аккуратно выполним все необходимые для Вас работы.</p>
                            <p>Всё как Вам будет угодно!</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className={styles.mainTitle}>
                            <h2>Family Serives: Болгарская команда</h2>
                        </Col>
                        <Col xs={12} lg={6}>
                            <p>Болгария-страна контрастов, с самыми необычными местами, в которых хочется побывать и полюбоваться их красотой. Золотые песчаные пляжи, скалистые горы, мистические пещеры, озёра и красивые ленты рек. Все эти красоты не получится кратко описать словами. Всё это надо увидеть и везде необходимо побывать!</p>
                            <p>Болгария третья страна Европы, после Греции и Италии, по количеству объектов, охраняемых ЮНЕСКО. Один из них красивый и уютный город Несебр-живописный древний курорт на Черноморском побережье Болгарии где и находится наш офис.</p>
                            <p>Несебр расположен на скалистом полуострове, связанном с материком узким перешейком. Курорт находится в 5км. от Солнечного Берега и в 30км. от от города Бургас, где находится южный аэропорт "Бургас".</p>
                            <p>Несебр принято условно делить на две части. Новый Несебр, который расположен на материке и старый город на полуострове. Несебр сохранил настоящие шедевры Болгарской и средневековой культуры и архитектуры. Старинные храмы, древние городские ворота, часть крепостных стен и акрополь. Церковь Святой Богородицы известна во всем мире своей чудотворной иконой.</p>
                        </Col>
                        <Col className='mb-3 mb-lg-0' xs={12} lg={6}><img width='100%' src="/img/nesebar.jpg" alt="Sea Resort"/></Col>
                        <Col>
                            <p>Несебр отличное место для комфортного отдыха. Красивые пляжи, чистое море, скалы и неповторимые пейзажи создают атмосферу уюта и гармонии.</p>
                            <p>Черноморское побережье Болгарии как жемчугом усеяно известными курортами: <br /> Несебр, Солнечный Берег, Святой Влас, Поморье, Созополь, Елените, Обзор, Золотые пески, Ривьера, Балчик, Албена, Кранево, Дюны, Приморско, Святой Константин и Елена.</p>

                            <p>А самые известные горные курорты: <b>Пампорово, Банско и Боровец.</b></p>
                            <p>Если Вы живёте в Болгарии и у Вас есть недвижимость или Вы приехали отдыхать, работать, учиться и позаботиться о своем здоровье, возможно наши услуги и сервис помогут Вам сделать Ваше пребывание в стране приятным и беззаботным.</p>
                            <p>В Болгарии, наша команда предлагает для Вас:</p>

                            <ul>
                                <li>Поддержка недвижимости</li>
                                <li>Авторемонтные услуги и сервис</li>
                                <li>Аренда автомобилей</li>
                                <li>Медицинские услуги</li>
                                <li>Развлекательные программы</li>
                                <li>Отдых для детей</li>
                                <li>Туристические услуги</li>
                                <li>Информационные услуги</li>
                                <li>Юридические услуги</li>
                            </ul>

                            <p>Всё, как Вам будет угодно!</p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </PageWrapper>
    )
}

export default Home;
