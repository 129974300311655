import { Container, Row, Col } from 'react-bootstrap';
import styles from './index.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const Footer = () => {
    const url = '/contact/';
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
        const isValidEmail = emailRegex.test(email);
        const form = e.currentTarget;

        if (!form.checkValidity() || !isValidEmail) {
            e.stopPropagation();
            setValidated(true)
            return;
        }

        setValidated(true)

        if (isValidEmail && name.length && phone.length) {
            setLoading(true)

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    email,
                    phone
                }),
            })
            .then(response => {
                setLoading(false)

                if (response.ok) {
                    setAlertMessage('Сообщение успешно отправлено!') 

                    setName('');
                    setPhone('');
                    setEmail('');

                    setValidated(false);
                } else {
                    setAlertMessage('Сообщение не отправлено, попробуйте еще раз!')
                }
                
                setShow(true)
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
            })
        }
    }

    return (
        <footer>
            <section className={styles['how-we-works'] + ' pt-5'}>
                <Container>
                    <h2 className="mb-5 text-center text-uppercase">Как мы работаем</h2>

                    <Row className="px-2 px-md-0" xs={1} sm={2} xl={4}>
                        <Col>
                            <div className={styles.numberDash}>1</div>
                            <h3>Всегда на связи</h3>
                            <p>
                                Просто свяжитесь с нами удобным для Вас способом.
                            </p>
                        </Col>
                        <Col>
                            <div className={styles.numberDash}>2</div>
                            <h3>Обсуждение</h3>
                            <p>
                                Мы внимательно Вас выслушаем и подробно обговорим с Вами все интересующие Вас вопросы.
                            </p>
                        </Col>
                        <Col>
                            <div className={styles.numberDash}>3</div>
                            <h3>Решение</h3>
                            <p>
                                Мы быстро и рассудительно найдём и предложим для Вас лучшие варианты решения Ваших вопросов.
                            </p>
                        </Col>
                        <Col>
                            <div className={styles.numberDash}>4</div>
                            <h3>Выполнение</h3>
                            <p>
                                Определим объективные и комфортные для Вас сроки выполнения поставленных Вами задач.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={styles.contacts}>
                <Container>
                    <Row>
                        <Col sm={10} md={8} lg={6} className="mx-auto px-4 px-xl-5">
                            <div className={styles.contactsInfo + ' px-xl-3'}>
                                <h2>Давайте с Вами всё обсудим</h2>
                                <p>Оставить заявку можно заполнив форму обратной связи!</p>
                                <p>Или звоните: <a href='tel:+359 87 985 95 28'> +359 87 985 95 28</a></p>
                            </div>
                        </Col>

                        <Col sm={12} lg={6}>
                            <div className={styles.contactForm}>
                                <h2>Оставьте заявку онлайн</h2>
                                <h3 className="mb-3">Наш оператор перезвонит в течении 10 минут</h3>

                                {show ? <Alert show={show} variant={alertMessage === 'Сообщение успешно отправлено!' ? 'success' : 'danger'}>
                                    {alertMessage}
                                </Alert> : ''}


                                <Form noValidate validated={validated} onSubmit={handleSubmit} className={styles.form}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control required type="text" value={name} disabled={loading} onInput={e => setName(e.target.value)} placeholder="Ваше имя*" />
                                        <Form.Control.Feedback className='text-warning' type="invalid">
                                            Пожалуйста, напишите свое имя
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Control required type="text" value={phone} disabled={loading} onInput={e => setPhone(e.target.value)} placeholder="Ваш телефон" />
                                        <Form.Control.Feedback className='text-warning' type="invalid">
                                            Пожалуйста, напишите свой телефон
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control required type="email" value={email} disabled={loading} onInput={e => setEmail(e.target.value)} placeholder="Ваш email" />
                                        <Form.Control.Feedback className='text-warning' type="invalid">
                                            Пожалуйста, введите правильный адрес электронной почты
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button className={styles.btnSubmit + " w-100 text-uppercase"} type="submit" disabled={loading}>
                                        {!loading ? <></> : <i className="fa-solid fa-cog fa-spin me-2"></i>}
                                        Отправитъ заявку
                                        {!loading ? '' : '...'}
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={styles.footerBottom + ' pt-5'}>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} lg={3}>
                            <ul>
                                <li>Компания</li>
                                <li><Link to='/about'>О нас</Link></li>
                                <li><Link to='/vacantions'>Вакансии</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <ul>
                                <li>Информация</li>
                                <li><Link to='/artgroup'>Артгруппа</Link></li>
                                <li><a href="email:info@familyservice.online">info@familyservice.online</a></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <ul>
                                <li>Контакты</li>
                                <li><a href="tel:+359 87 985 95 28">+359 87 985 95 28</a></li>
                                <li><a href="email:info@familyservice.online">info@familyservice.online</a></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <ul className={styles.socialIcons}>
                                <li>Напишете нам</li>
                                <li>
                                    <a href="viber://chat?number=%2B359879859528"><i style={{ color: '#7360f2' }} className="fa-brands fa-viber"></i></a>
                                    {/* <a href="https://facebook.com"><i className="fa-brands fa-facebook"></i></a> */}
                                    <a href="https://wa.me/359879859528"><i style={{ color: '#4ecb5c' }} className="fa-brands fa-whatsapp"></i></a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className={styles.trademark + ' text-center py-4'}>
                    <Row>
                        <Col>
                            <p>&copy; Family-services: Портал углуг в Болгарии</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </footer>
    )
}

export default Footer;
