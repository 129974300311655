import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const Medical = () => {
    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Медицина</h3>
                        <p>Иногда возникает ситуация, когда нужна медицинская помощь. Мы готовы прийти Вам на помощь. Наши врачи и медицинские центры-партнеры окажут Вам необходимую стоматологическую и врачебную помощь. Мы поможем Вам с переводом и транспортом и сопровождением в необходимый медицинский центр, который укажите Вы или который мы, по Вашим критериям, подберем для Вас.</p>
                    </Col>
                    <Col md={6}>
                        <p><b>К Вашим услугам:</b></p>

                        <ul>
                            <li>Срочная стоматологическая помощь</li>
                            <li>Полный спектр стоматологических услуг в удобное для Вас время</li>
                            <li>Детская стоматология</li>
                            <li>Терапевт</li>
                            <li>Офтальмолог</li>
                            <li>Репродуктивная медицина</li>
                            <li>Гинекология</li>
                            <li>Урология</li>
                        </ul>

                        <p><b>Также предоставляем:</b></p>

                        <ul>
                            <li>Услуги медицинских центров Германии, Болгарии, Украины, России,Израиля, Кипра</li>
                            <li>Медицинский туризм</li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <img width="100%" src="/img/medical.jpg" alt="Medical services" />
                    </Col>
                    <Col xs={12}>
                    <h3 className="text-center mb-4 mt-5">Наши Партнёры в Германии</h3>
                    <p><b>Клиника доктора Хоффманн, Берлин. Центр репродуктивной медицины</b></p>

                    <ul>
                        <li>Лечение бесплодия</li>
                        <li>Терапия гормональных нарушений</li>
                        <li>Андрологическая микрохирургия</li>
                        <li>Микро-ТЕСЕ</li>
                        <li>Лечебное питание</li>
                        <li>Классическая гомеопатия</li>
                        <li>Урология-Андрология</li>
                    </ul>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default Medical;
