import PageWrapper from "../../components/page-wrapper";
import SimpleArticle from "../../components/simple-article";
import { Container, Row, Col } from 'react-bootstrap';


const Vacantions = () => {
    return (
        <PageWrapper>
            <Container>
                <Row>
                    <Col>
                        <SimpleArticle
                            bigMarginStyle={true}
                            title="Вакансии"
                            text1="В связи с ослаблением карантинных ограничений во время пандемии COVID-19 и как следствие увеличением количества поступающих заявок на выполнение некоторых видов работ, наша Компания приглашает к сотрудничеству профильных специалистов."
                            listTitle='Рады будем видеть в нашей команде:'
                            listItems={[
                                'Мастер по кондиционерам',
                                'Мастер по стиральным машинам',
                                'Мастер по бойлерам',
                                'Мастер по электрооборудованию и электропроводке',
                                'Мастер по сантехнике и трубопроводам',
                                'Мастер по слесарным работам',
                                'Мастер по столярным работам',
                                'Мастер по кровле',
                            ]}
                        />
                        <SimpleArticle 
                            listTitle='Требования Компании:'
                            listItems={[
                                'Желание и умение вежливого общения с Клиентами Компании',
                                'Пунктуальность',
                                'Аккуратность',
                                'Честность'
                            ]}
                        />
                        <SimpleArticle 
                            listTitle='Особенности работы:'
                            listItems={[
                                'Выездной ремонт на территории Клиентов',
                                'Проведение диагностических работ, составление и согласование перечня и сроков проведения работ с Клиентами',
                                'Удалённая отчётность',
                                'Персональный график работы',
                                'Стабильное поступление заявок на выполнение работ'
                            ]}
                        />
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default Vacantions;
