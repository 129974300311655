import {
    BrowserRouter as Switch,
    Routes,
    Route
} from 'react-router-dom';
import About from './pages/about';
import AppliancesRepair from './pages/appliances-repair';
import Artgroup from './pages/artgroup';
import AutoServices from './pages/auto-repair';
import ForChildren from './pages/children';
import RealEstate from './pages/estate';
import Home from './pages/home';
import LawServices from './pages/law-services';
import Medical from './pages/medical';
import Repairer from './pages/repairer';
import Souvenirs from './pages/souvenirs';
import Tourism from './pages/tourism';
import Vacantions from './pages/vacantions';
import ScrollToTheContent from './tools';


const Navigation = () => {
    return (
        <Switch>
            <ScrollToTheContent>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/artgroup" element={<Artgroup />}></Route>
                    <Route path="/vacantions" element={<Vacantions />}></Route>
                    <Route path="/repairer" element={<Repairer />}></Route>
                    <Route path="/law-services" element={<LawServices />}></Route>
                    <Route path="/appliances" element={<AppliancesRepair />}></Route>
                    <Route path="/tourism" element={<Tourism />}></Route>
                    <Route path="/for-children" element={<ForChildren />}></Route>
                    <Route path="/autoservice" element={<AutoServices />}></Route>
                    <Route path="/medical" element={<Medical />}></Route>
                    <Route path="/souvenirs" element={<Souvenirs />}></Route>
                    <Route path="/estate" element={<RealEstate />}></Route>
                </Routes>
            </ScrollToTheContent>
        </Switch>
    )
}

export default Navigation;
