import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const LawServices = () => {
    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Юридические услуги</h3>
                    </Col>
                    <Col md={6}>
                        <p>Высокий темп и повышенные требования современной жизни требуют от нас постоянного принятия разнообразных решений, как простых, так и сложных, в очень сжатые сроки. Иногда, чрезмерно большой и сложный объём быстро меняющейся информации делает процесс решения жизненных ситуаций, проблем и повседневных задач слишком трудоёмким, а иногда и невозможным без помощи профильных специалистов.</p>
                        <p>Покупка, продажа или аренда недвижимости, офиса, автомобиля, ДТП, оформление банковских услуг и документов, доверенностей, языковых переводов, передача имущественных, земельных и других прав, оформление и оплата коммунальных услуг, налогов и штрафов, получение различных разрешений практически невозможно без участия юриста, адвоката, нотариуса, бухгалтера, переводчика. Находясь в другой стране на отдыхе, учебе или работе невозможно избежать возникновения трудных ситуаций, требующих вмешательства и помощи узкопрофильных профессионалов, сотрудничество с которыми в современной жизни не трудно организовать даже дистанционно. Если у Вас возникла такая ситуация, наша команда поможет Вам!</p>
                    </Col>
                    <Col md={6}>
                        <img className="d-block mx-auto py-3 py-md-0" width="80%" src="/img/law.jpg" alt="Law services" />
                    </Col>
                    <Col xs={12}>
                        <p>Мы организуем для Вас в кратчайшие сроки консультацию с необходимыми Вам профессиональными специалистами, профиль деятельности и компетенция которых находится в области решений вопросов и проблем, которые Вам необходимо урегулировать. Наши сотрудники проследят за всем ходом выполнения поставленных Вами задач от начала и до конца.</p>
                        <p>Мы будем на связи с Вами 24/7, организуем транспорт и сопровождение.</p>
                        <p>Наша команда всегда будет действовать исключительно в Ваших интересах!</p>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default LawServices;
