import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

const Hamburger = () => {
     const styles = {
        bmBurgerButton: {
          position: 'fixed',
          width: '36px',
          height: '34px',
          right: '36px',
          top: '36px',
          background: '#f8f9fa',
          borderRadius: '2px',
          boxShadow: '0 0 2px 8px #f8f9fa'
        },
        bmBurgerBars: {
          background: '#2b3a67',
        },
        bmBurgerBarsHover: {
          background: '#a90000'
        },
        bmCrossButton: {
          height: '24px',
          width: '24px'
        },
        bmCross: {
          background: '#bdc3c7'
        },
        bmMenuWrap: {
          position: 'fixed',
          top: '0',
          height: '100%'
        },
        bmMenu: {
          background: '#373a47',
          padding: '2.5em 1.5em 0',
          fontSize: '1.15em'
        },
        bmMorphShape: {
          fill: '#373a47'
        },
        bmItemList: {
          color: '#b8b7ad',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0.8em'
        },
        bmItem: {
          display: 'block',
          width: '100%',
          color: '#fff',
          textTransform: 'uppercase',
          textDecoration: 'none',
          marginBottom: '1rem',
          paddingBottom: '10px',
          fontSize: '14px',
          borderBottom: '2px solid rgba(0,0,0,0.1)'
        },
        bmOverlay: {
          background: 'rgba(0, 0, 0, 0.3)'
        }
      }

    return (
        <Menu right styles={styles} >
            <Link to="/" id="home" className="menu-item">&gt; Начало</Link>
            <Link to="/medical" id="medical" className="menu-item">&gt; Медицина</Link>
            <Link to="/autoservice" id="autoservice" className="menu-item">&gt; Автомобильные услуги</Link>
            <Link to="/for-children" id="for-children" className="menu-item">&gt; Для детей</Link>
            <Link to="/artgroup" id="artgroup" className="menu-item">&gt; Артгрупа</Link>
            <Link to="/estate" id="estate" className="menu-item">&gt; Недвижимость</Link>
            <Link to="/souvenirs" id="souvenirs" className="menu-item">&gt; Авторские подарки и сувениры</Link>
            <Link to="/tourism" id="tourism" className="menu-item">&gt; Туризм и путешествия</Link>
            <Link to="/appliances" id="appliances" className="menu-item">&gt; Ремонт бытовой техники</Link>
            <Link to="/law-services" id="law-services" className="menu-item">&gt; Юридические услуги</Link>
            <Link to="/repairer" id="repairer" className="menu-item">&gt; Мастер на час</Link>
        </Menu>
    )
}

export default Hamburger;
