import PageWrapper from "../../components/page-wrapper";
import { Container, Row, Col } from 'react-bootstrap';

const AppliancesRepair = () => {
    return (
        <PageWrapper>
            <Container>
                <Row className="mb-5" >
                    <Col xs={12}>
                        <h3 className="text-center my-4">Ремонт бытовой техники</h3>
                    </Col>
                    <Col xs={12}>
                        <p>Сегодня, комфорт в доме определяется наличием необходимого набора бытовой техники. Современная бытовая техника производится различными мировыми компаниями достаточно высокого качества, надёжная, энергоэффективная и простая в эксплуатации. К сожалению, ни что не вечно. Большое влияние на надёжность и работоспособность бытовой техники оказывают неблагоприятные эксплуатационные условия. Низкое качество воды и отсутствие хорошей фильтрации, перепады напряжения в электросети, качество газа и нестабильное давление в системе, ошибки при производстве, доставке и установке оборудования могут привести к мелким, а иногда и к крупным поломкам и отказам.</p>
                    </Col>
                    <Col md={6}>
                    <p>Наступает неприятный момент необходимости проведения ремонта, регулировочных работ или замены оборудования. В такой момент возникает только одно желание, быстро и без хлопот исправить ситуацию. Вернуться в зону комфорта Вам помогут наши квалифицированные мастера по установке, ремонту и обслуживанию всего спектра бытовой техники. Чтобы не случилось с вашим кондиционером, холодильником, бойлером, плитой, посудомоечной машиной или газовой колонкой и другими бытовыми устройствами, наши профессиональные специалисты быстро и качественно помогут Вам устранить возникшие проблемы.</p>
                    <p>Мы поможем осуществить установку, регулировку и:</p>
                    
                    <ul>
                        <li>Ремонт кондиционеров</li>
                        <li>Ремонт бойлеров</li>
                        <li>Ремонт стиральных машин</li>
                        <li>Ремонт посудомоечных машин</li>
                        <li>Ремонт холодильников</li>
                        <li>Ремонт электроплит</li>
                    </ul>
                    </Col>
                    <Col md={6}>
                        <img className="mx-auto d-block py-4 py-md-0" width="90%" src="/img/washing-machine.jpg" alt="Appliances repair services" />
                    </Col>
                    <Col xs={12}>
                        <p>Мы организуем для Вас в кратчайшие сроки консультацию с необходимыми Вам профессиональными специалистами, профиль деятельности и компетенция которых находится в области решений вопросов и проблем, которые Вам необходимо урегулировать. Наши сотрудники проследят за всем ходом выполнения поставленных Вами задач от начала и до конца.</p>
                        <p>Мы будем на связи с Вами 24/7, организуем транспорт и сопровождение.</p>
                        <p>Наша команда всегда будет действовать исключительно в Ваших интересах!</p>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default AppliancesRepair;
